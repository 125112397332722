<template>
    <section>
        <b-overlay :show="loading"
                   :variant="variant"
                   :opacity="opacity"
                   :blur="blur"
                   spinner-variant="primary"
                   spinner-type="grow"
                   spinner-large
                   class="loaderPreview"
                   rounded="sm">

            <b-row v-if="(meta.breadcrumb || meta.pageTitle) && dataLoaded"
                   class="content-header">

                <b-col class="content-header-left mb-2"
                       cols="12"
                       md="6">
                    <b-row class="breadcrumbs-top">
                        <b-col cols="12">
                            <h2 class="content-header-title float-left pr-1 mb-0">
                                {{ meta.pageTitle }}
                            </h2>
                            <div class="breadcrumb-wrapper">
                                <b-breadcrumb>
                                    <b-breadcrumb-item to="/">
                                        <feather-icon icon="HomeIcon"
                                                      size="16"
                                                      class="align-text-top" />
                                    </b-breadcrumb-item>
                                    <b-breadcrumb-item v-for="item in meta.breadcrumb"
                                                       :key="item.text"
                                                       :active="item.active"
                                                       :to="item.to">
                                        {{ item.text }}
                                    </b-breadcrumb-item>
                                </b-breadcrumb>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <!-- Content Right -->
                <b-col class="content-header-right text-md-right d-md-block d-none mb-1"
                       md="6"
                       cols="12">
                    <b-button @click="getSMTPDetail"
                              data-id="0"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="outline-success">
                        <feather-icon icon="PlusIcon" size="18" />
                        Add New SMTP
                    </b-button>
                </b-col>
            </b-row>
            <div class="post" v-if="error">

                <div v-if="error" class="error">
                    {{ error }}
                </div>
            </div>

            <b-row class="match-height" v-if="funnelUserSmtpConfiguration && dataLoaded">
                <b-col lg="12">
                    <b-card>
                        <div class="pb-2">
                            <div class="row d-flex justify-content-between  flex-wrap">
                                <b-col lg="8"></b-col>
                                <b-col lg="4">
                                    <b-form-group label="Search" v-if="totalRows>0"
                                                  label-cols-sm="3"
                                                  label-align-sm="right"
                                                  label-size="sm"
                                                  label-for="filterInput"
                                                  class="mb-0 align-items-center">
                                        <b-input-group size="sm">
                                            <b-form-input id="filterInput"
                                                          v-model="filter"
                                                          type="text"
                                                          placeholder="Type to Search" />
                                            <b-input-group-append>
                                                <b-button :disabled="!filter"
                                                          @click="filter = ''">
                                                    Clear
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </div>
                        </div>
                        <b-table striped
                                 hover
                                 bordered
                                 responsive
                                 fixed
                                 class="position-relative"
                                 :per-page="perPage"
                                 :current-page="currentPage"
                                 :items="funnelUserSmtpConfiguration"
                                 :fields="smtpTablefields"
                                 :sort-by.sync="sortBy"
                                 :sort-desc.sync="sortDesc"
                                 :sort-direction="sortDirection"
                                 :filter="filter"
                                 :filter-included-fields="filterOn"
                                 @filtered="onFiltered"
                                 show-empty>
                            <template #empty="scope">
                                Record doesn't exist
                            </template>
                            <template v-slot:IsDefault="data">
                                {{data.item.IsDefault==true?'True':'False'}}
                            </template>
                            <template v-slot:cell(Id)="data">
                                <b-button :data-id="data.item.Id"
                                          @click="openSendTestEmailModal"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="flat-info"
                                          title="Send Test Email"
                                          v-b-tooltip.hover.v-info
                                          class="btn-icon">
                                    <feather-icon icon="MailIcon" />

                                </b-button>

                                <b-button :data-id="data.item.Id"
                                          title="Edit"
                                          @click="getSMTPDetail"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          v-b-tooltip.hover.v-info
                                          variant="flat-info"
                                          class="btn-icon">
                                    <feather-icon icon="EditIcon" />
                                </b-button>

                                <b-button :data-id="data.item.Id"
                                          title="Delete"
                                          @click="deleteSMTPDetail"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          v-b-tooltip.hover.v-danger
                                          variant="flat-danger"
                                          class="btn-icon">
                                    <feather-icon icon="ArchiveIcon" />
                                </b-button>
                            </template>
                        </b-table>

                        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

                            <!-- page length -->
                            <b-form-group label="Show"
                                          label-cols="4"
                                          label-align="center"
                                          label-size="sm"
                                          label-for="sortBySelect"
                                          class="text-nowrap mb-md-0 mr-1">
                                <b-form-select id="perPageSelect"
                                               v-model="perPage"
                                               size="sm"
                                               inline
                                               :options="pageOptions" />
                            </b-form-group>

                            <!-- pagination -->
                            <div v-if="totalRows > perPage">
                                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

                                    <!-- page length -->
                                    <b-form-group label="Show"
                                                  label-cols="4"
                                                  label-align="center"
                                                  label-size="sm"
                                                  label-for="sortBySelect"
                                                  class="text-nowrap mb-md-0 mr-1">
                                        <b-form-select id="perPageSelect"
                                                       v-model="perPage"
                                                       size="sm"
                                                       inline
                                                       :options="pageOptions" />
                                    </b-form-group>

                                    <!-- pagination -->
                                    <div>
                                        <b-pagination v-model="currentPage"
                                                      :total-rows="totalRows"
                                                      :per-page="perPage"
                                                      first-number
                                                      last-number
                                                      prev-class="prev-item"
                                                      next-class="next-item"
                                                      class="mb-0">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon"
                                                              size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon"
                                                              size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </b-card-body>
                            </div>
                        </b-card-body>


                    </b-card>
                </b-col>
            </b-row>

            <b-modal id="addSMTPModal"
                     centered
                     size="lg"
                     title="Add SMTP">

                <validation-observer ref="smtpRules">

                    <form ref="smtpCreate" id="smtpCreate"
                          @submit.stop.prevent>


                        <div id="FunnalGoalOneSteps" v-if="smtpConfigurationDetail">
                            <b-form novalidate
                                    class="needs-validation"
                                    @submit.prevent>
                                <b-form-row>
                                    <b-col lg="6">
                                        <b-form-group>
                                            <label for="Title">SMTP Name<span class="required"> *</span></label>
                                            <validation-provider #default="{ errors }"
                                                                 rules="required"
                                                                 name="Title">
                                                <b-form-input id="Title"
                                                              v-model="smtpConfigurationDetail.Title"
                                                              placeholder="Please Enter SMTP Name"
                                                              :state="errors.length > 0 ? false:null" />
                                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>


                                    <b-col lg="6">
                                        <b-form-group>
                                            <label for="DefaultSMTP">Default SMTP<span class="required"> *</span></label>
                                            <select class="form-control" name="DefaultSMTP" id="DefaultSMTP" @change="onDefaultSMTPChange($event)" v-model="smtpConfigurationDetail.IsDefault">
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="6">
                                        <b-form-group>
                                            <label for="FromName">From Name<span class="required"> *</span></label>
                                            <validation-provider #default="{ errors }"
                                                                 rules="required"
                                                                 name="From Name">
                                                <b-form-input id="FromName"
                                                              v-model="smtpConfigurationDetail.FromName"
                                                              placeholder="Please Enter From Name"
                                                              :state="errors.length > 0 ? false:null" />
                                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="6">
                                        <b-form-group>
                                            <label for="FromEmail">From Email<span class="required"> *</span></label>
                                            <validation-provider #default="{ errors }"
                                                                 rules="required|email"
                                                                 name="From Email">
                                                <b-form-input id="FromEmail"
                                                              v-model="smtpConfigurationDetail.FromEmail"
                                                              placeholder="Please Enter From Email"
                                                              :state="errors.length > 0 ? false:null" />
                                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="6">
                                        <b-form-group>
                                            <label for="SmtpServer">SMTP Server/Hostname/IP Address<span class="required"> *</span></label>
                                            <validation-provider #default="{ errors }"
                                                                 rules="required"
                                                                 name="SMTP Server">
                                                <b-form-input id="SmtpServer"
                                                              v-model="smtpConfigurationDetail.SmtpServer"
                                                              placeholder="Please Enter SMTP Server"
                                                              :state="errors.length > 0 ? false:null" />
                                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4">
                                        <b-form-group>
                                            <label for="SmtpPort">SMTP Port<span class="required"> *</span></label>
                                            <validation-provider #default="{ errors }"
                                                                 rules="required"
                                                                 name="Smtp Port">
                                                <b-form-input id="SmtpPort"
                                                              v-model="smtpConfigurationDetail.SmtpPort"
                                                              placeholder="Please Enter SMTP Port"
                                                              :state="errors.length > 0 ? false:null" />
                                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="2">

                                        <b-form-group label="Enable SSL" label-for="EnableSSL">

                                            <select class="form-control" name="EnableSSL" id="EnableSSL" @change="onSSLChange($event)" v-model="smtpConfigurationDetail.EnableSSL">
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </b-form-group>


                                    </b-col>
                                    <b-col lg="6">
                                        <b-form-group>
                                            <label for="SmtpUser">SMTP User<span class="required"> *</span></label>
                                            <validation-provider #default="{ errors }"
                                                                 rules="required"
                                                                 name="Smtp User">
                                                <b-form-input id="SmtpUser"
                                                              v-model="smtpConfigurationDetail.SmtpUser"
                                                              placeholder="Please Enter SMTP User"
                                                              :state="errors.length > 0 ? false:null" />
                                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="6">
                                        <b-form-group>
                                            <label for="SmtpPassword">SMTP Password<span class="required"> *</span></label>
                                            <validation-provider #default="{ errors }"
                                                                 rules="required"
                                                                 name="Smtp Password">
                                                <b-form-input id="SmtpPassword"
                                                              v-model="smtpConfigurationDetail.SmtpPassword"
                                                              type="password"
                                                              placeholder="Please Enter SMTP Password"
                                                              :state="errors.length > 0 ? false:null" />
                                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="12" class="hidden">
                                        <b-form-group label="Smtp Domain"
                                                      label-for="SmtpDomain">
                                            <b-form-input id="SmtpDomain"
                                                          v-model="smtpConfigurationDetail.SmtpDomain"
                                                          placeholder="Please Enter SMTP Domain" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="12" class="hidden">
                                        <b-form-group label="Smtp Footer"
                                                      label-for="SmtpFooter">

                                            <b-form-input id="SmtpFooter"
                                                          v-model="smtpConfigurationDetail.SmtpFooter"
                                                          placeholder="Please Enter SMTP Footer" />
                                        </b-form-group>
                                    </b-col>



                                </b-form-row>
                            </b-form>
                        </div>

                    </form>

                </validation-observer>
                <slot></slot>
                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closeSMTPModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>


                    <b-button @click="saveSMTP" id="btnSaveSMTP"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="SaveIcon" size="18" />
                        Save
                    </b-button>

                </template>
            </b-modal>

            <b-modal id="sendTestEmailModal" 
                     ref="modal"
                     title="Send Test Email"
                     ok-title="Submit"
                     @ok="handleEmailFormOk"
                     centered
                     size="lg">
                <validation-observer ref="testEmailRules">
                    <form ref="TestEmailFormFunnel"
                          id="TestEmailFormFunnel"
                          @submit.stop.prevent>
                        <b-row>
                            <b-col lg="12">
                                <b-form-group>
                                    <label for="TestToEmail">To Email<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required|email"
                                                         name="To Email">
                                        <b-form-input id="TestToEmail"
                                                      name="TestToEmail"
                                                      v-model="TestToEmail"
                                                      placeholder="Please Enter To Email"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="6">
                                <b-form-group>
                                    <label for="TestFromName">From Name<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="From Name">
                                        <b-form-input id="TestFromName"
                                                      name="TestFromName"
                                                      v-model="TestFromName"
                                                      placeholder="Please Enter From Name"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6">
                                <b-form-group>
                                    <label for="TestReplyToEmail">Reply To Email<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required|email"
                                                         name="Reply To Email">
                                        <b-form-input id="TestReplyToEmail"
                                                      name="TestReplyToEmail"
                                                      v-model="TestReplyToEmail"
                                                      placeholder="Please Enter Reply To Email"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label for="TestSubject">Subject<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Subject">
                                        <b-form-input id="TestSubject"
                                                      name="TestSubject"
                                                      v-model="TestSubject"
                                                      placeholder="Please Enter Subject"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label label-for="EmailText">Email Body<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Email Body">
                                        <quill-editor id="EmailText"
                                                      name="EmailText"
                                                      v-model="EmailText"
                                                      ref="quillEditor"
                                                      :options="snowOption"
                                                      :class="errors.length > 0 ? 'error' : 'valid'"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>

                            </b-col>

                        </b-row>
                    </form>
                </validation-observer>

                <slot></slot>
                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closeTestEmailModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>

                    <b-button @click="SendTestEmail"
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="MailIcon" size="18" />
                        Send
                    </b-button>
                </template>
            </b-modal>

        </b-overlay>
    </section>
</template>

<script>
    import BCardCode from '@core/components/b-card-code';
    import {
        BCard, BCardHeader, BCardBody, BCardImg, BRow, BCol, BCardText, BLink, BButton, BModal, VBModal,
        BFormInput, BForm, BFormRow, BFormValidFeedback, BFormInvalidFeedback, BFormGroup,
        BTabs, BTab, BTable, BFormSelect, BFormCheckbox, BFormRadio, BFormFile,
        BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormTextarea,
        BOverlay, BPagination, BAlert, BDropdownItem, BDropdown,
        BBreadcrumb, BBreadcrumbItem,
        VBTooltip,
    } from 'bootstrap-vue';

    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import {
        required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
    } from '@validations';
    import { $themeConfig } from "@themeConfig";
    import { VueGoodTable } from "vue-good-table";
    import { quillEditor } from "vue-quill-editor";
    import Ripple from 'vue-ripple-directive';
    import jwt from '@/auth/jwt/useJwt';
    import vSelect from 'vue-select';
    import { extend } from 'vee-validate';


    extend('required', {
        message: 'This Field is required',
    });
    extend('email', {
        message: 'Invalid email address',
    });

    export default {
        metaInfo: {
            title: 'Landingly | SMTP Configurations'
        },
        components: {
            BDropdownItem,
            vSelect,
            BCardCode,
            BCardText,
            BCard,
            BCardHeader,
            BRow,
            BCol,
            BCardBody,
            BCardImg,
            BLink,
            BModal,
            VBModal,
            BButton,
            BFormInput,
            BForm,
            BFormRow,
            BFormValidFeedback,
            BFormInvalidFeedback,
            BFormGroup,
            BTabs,
            BTab,
            BInputGroup,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormTextarea,
            VueGoodTable,
            BTable,
            BFormSelect,
            BFormCheckbox,
            BFormRadio,
            quillEditor,
            ValidationProvider,
            ValidationObserver,
            BOverlay,
            BPagination,
            BAlert,
            BFormFile,
            BBreadcrumb, BBreadcrumbItem,
            
        },
        data() {
            return {
                
                selected: { title: 'Square' },
                option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
                loading: true,
                variant: 'light',
                opacity: 0.85,
                blur: '2px',

                meta: {
                    pageTitle: 'SMTP Configurations',
                    breadcrumb: [
                        {
                            text: 'SMTP Configurations',
                            active: true
                        },
                    ],
                },

                dataLoaded: false,
                funnelUserSmtpConfiguration: null,
                smtpConfigurationDetail: null,

                TestToEmail:"",
                TestFromName:"",
                TestReplyToEmail:"",
                TestSubject:"",
                EmailText: "",
                SMTPConfigId: null,

                error: null,
                WebAPIURL: $themeConfig.app.apiURL,

                smtpTablefields: [{
                    key: 'Title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'FromName',
                    label: 'From Name',
                    sortable: true
                },
                {
                    key: 'FromEmail',
                    label: 'From Email',
                    sortable: true
                },
                //{
                //    key: 'SmtpServer',
                //    label: 'SMTP Server',
                //    sortable: true
                //},
                //{
                //    key: 'SmtpPort',
                //    label: 'SMTP Port',
                //    sortable: true
                //},
                //{
                //    key: 'SmtpUser',
                //    label: 'User',
                //    sortable: true
                //},
                {
                    key: 'IsDefault',
                    label: 'Is Default',
                    sortable: true,
                    thStyle: { width: "125px" },
                },
                {
                    key: 'Id',
                    label: 'Actions',
                    sortable: false,
                    thStyle: { width: "200px" },
                }],
                emailPageLength: 10,
                emailSearchTerm: '',
                perPage: 20,
                pageOptions: [10, 20, 50, 100],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],

                snowOption: {
                    theme: "snow",
                    placeholder: "Please enter it here...",
                    modules: {
                        toolbar: {
                            container: [
                                [{ placeholder: ["[GuestName]", "[HotelName]"] }], // my custom dropdown
                                ["bold", "italic", "underline", "strike"], //??bold, italic, underline, strikethrough
                                ["blockquote"], // reference
                                [{ header: 1 }, { header: 2 }], //??title, the form of the key - value pair; 1, 2 indicates the font size
                                [{ list: "ordered" }, { list: "bullet" }], // List
                                [{ indent: "-1" }, { indent: "+1" }], // Indent
                                [{ direction: "rtl" }], //??text direction
                                [{ size: ["small", false, "large", "huge"] }], // font size
                                [{ header: [1, 2, 3, 4, 5, 6, false] }], //??several levels of title
                                [{ color: [] }, { background: [] }], // font color, font background color
                                [{ font: [] }], //??font
                                [{ align: [] }], //??alignment
                                ["clean"], //??clear font style
                                ["link"],
                                ["image"], //Upload images, upload videos
                            ],
                            handlers: {
                                placeholder: function (value) {
                                    if (value) {
                                        const cursorPosition = this.quill.getSelection().index;
                                        this.quill.insertText(cursorPosition, value);
                                        this.quill.setSelection(cursorPosition + value.length);
                                    }
                                },
                                //image: this.imageHandler
                            },
                        },
                    },
                },

            }
        },
        directives: {
            'b-modal': VBModal,
            'b-tooltip': VBTooltip,
            Ripple,
        },
        created() {
            this.fetchSmtpsData();
        },
        beforeCreate() {
            
        },
        watch: {
            '$route': 'fetchSmtpsData'
        },

        methods: {

            onFiltered(filteredItems) {
                //this.totalRows = filteredItems.length
                //this.currentPage = 1
            },

            deleteSMTPDetail: async function (e) {
                const Id = e.currentTarget.getAttribute('data-id');
                this.error = null
                var self = this;

                var axios = require('axios');
                var qs = require('qs');
                const access_token = await jwt.getToken();

                var headers = {
                    'Authorization': 'bearer ' + access_token
                };

                var data = qs.stringify({
                    'Id': Id
                });
                this.$swal({
                    title: 'Delete SMTP Setting',
                    text: "Are you sure?",
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        self.loading = true;
                        var config = {
                            method: 'post',
                            url: '' + this.WebAPIURL + '/api/projects/RemoveUserConfig?' + data,
                            headers: {
                                'Authorization': 'bearer ' + access_token
                            },
                            data: data
                        }

                        axios(config)
                            .then(function (response) {
                                self.fetchSmtpsData();
                                self.loading = false;

                            })
                            .catch(function (error) {
                                console.log(error);
                                self.loading = false;
                            });
                    }
                })
            },

            getSMTPDetail: async function (e) {
                const Id = e.currentTarget.getAttribute('data-id');
                this.error = null
                var self = this;
                this.loading = true
                var axios = require('axios');
                var qs = require('qs');
                const access_token = await jwt.getToken();

                var headers = {
                    'Authorization': 'bearer ' + access_token
                };

                var data = qs.stringify({
                    'Id': Id
                });

                var config = {
                    method: 'get',
                    url: '' + this.WebAPIURL + '/api/projects/SMTPConfigurationDetail?' + data,
                    headers: {
                        'Authorization': 'bearer ' + access_token
                    },
                    data: data
                }

                axios(config)
                    .then(function (response) {
                        self.smtpConfigurationDetail = response.data;
                        if (Id < 1) {
                            self.smtpConfigurationDetail.EnableSSL = true;
                            self.smtpConfigurationDetail.IsDefault = false;
                            
                            
                        }
                        self.loading = false;
                        self.$nextTick(() => {
                            self.$bvModal.show('addSMTPModal')
                        })

                    })
                    .catch(function (error) {
                        console.log(error);
                        self.loading = false;
                    });
            },

            closeSMTPModal() {
                this.$bvModal.hide("addSMTPModal");
            },

            saveSMTP: async function () {
                const access_token = await jwt.getToken();
                this.$refs.smtpRules.validate().then(success => {
                    if (success) {
                        debugger;

                        this.error = null
                        var self = this;
                        this.loading = true
                        var axios = require('axios');
                        var qs = require('qs');

                        var data = qs.stringify({
                            'Title': this.smtpConfigurationDetail.Title,
                            'FromName': this.smtpConfigurationDetail.FromName,
                            'FromEmail': this.smtpConfigurationDetail.FromEmail,
                            'SmtpServer': this.smtpConfigurationDetail.SmtpServer,
                            'SmtpPort': this.smtpConfigurationDetail.SmtpPort,
                            'SmtpUser': this.smtpConfigurationDetail.SmtpUser,
                            'SmtpPassword': this.smtpConfigurationDetail.SmtpPassword,
                            'SmtpDomain': '',
                            'SmtpFooter': '',
                            'IsDefault': this.smtpConfigurationDetail.IsDefault,
                            'EnableSSL': this.smtpConfigurationDetail.EnableSSL,
                            'SSLPort': '',
                            'Id': this.smtpConfigurationDetail.Id,
                        });

                        var config = {
                            method: 'post',
                            url: '' + this.WebAPIURL + '/api/projects/SaveSMTPConfiguration?' + data,
                            headers: {
                                'Authorization': 'bearer ' + access_token
                            },
                            data: data
                        }

                        axios(config)
                            .then(function (response) {
                                self.$swal(
                                    "Success",
                                    "",
                                    "success"
                                );

                                self.funnelUserSmtpConfiguration = response.data;

                                self.loading = false;
                                self.$nextTick(() => {
                                    self.$bvModal.hide('addSMTPModal')
                                })
                            })
                            .catch(function (error) {
                                self.$swal(
                                    "Error",
                                    "",
                                    "error"
                                );
                                self.loading = false;
                            });
                    }
                    else {

                    }
                })
            },

            fetchSmtpsData: async function () {

                this.error = this.funnelUserSmtpConfiguration = null
                var self = this;
                this.loading = true
                var axios = require('axios');
                const access_token = await jwt.getToken();

                var headers = {
                    'Authorization': 'bearer ' + access_token
                };

                axios.get('' + this.WebAPIURL + '/api/projects/SMTPConfigurations', { headers }).then(function (response) {
                    self.funnelUserSmtpConfiguration = response.data;
                    self.loading = false;
                    self.dataLoaded = true;
                    self.totalRows = response.data.length;
                })
                    .catch(function (error) {
                        self.error = error;
                        self.loading = false;
                        self.dataLoaded = true;
                        console.log("error:: " + error)
                    });
            },

            onDefaultSMTPChange(event) {
                this.smtpConfigurationDetail.IsDefault = event.target.value;
                console.log(this.smtpConfigurationDetail.IsDefault);


            },

            openSendTestEmailModal: async function (e) {
                const Id = e.currentTarget.getAttribute('data-id');
                var self = this;
                this.SMTPConfigId = Id;

                this.resetTestEmailModal();

                self.$nextTick(() => {
                    self.$bvModal.show("sendTestEmailModal");
                });
            },

            closeTestEmailModal() {
                this.$bvModal.hide("sendTestEmailModal");
            },

            handleEmailFormOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.SendTestEmail();
            },

            resetTestEmailModal() {
                this.TestFromName = '';
                this.TestSubject = '';
                this.TestToEmail = '';
                this.TestReplyToEmail = '';
                this.EmailText = '';
            },

            SendTestEmail: async function () {
                const access_token = await jwt.getToken();
                this.$refs.testEmailRules.validate().then((success) => {
                    if (success) {
                        this.loading = true;

                        var self = this;
                        var qs = require("qs");
                        const originalEmailText = this.EmailText;
                        const textEncoder = new TextEncoder('iso-8859-1');
                        const encodedEmailText = textEncoder.encode(originalEmailText);
                        let formdata = new FormData();
                        formdata.append('FromName', this.TestFromName);
                        formdata.append('Subject', this.TestSubject);
                        formdata.append('EmailText', window.btoa(String.fromCharCode(...encodedEmailText)));
                        formdata.append('SendToEmail', this.TestToEmail);
                        formdata.append('ReplyToEmail', this.TestReplyToEmail);
                        formdata.append('SMTPConfigId', this.SMTPConfigId);
                        var axios = require("axios");

                        axios
                            .post("" + this.WebAPIURL + "/api/projects/TestSmtpEmail",
                                formdata, {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            })
                            .then(function (response) {
                                self.$nextTick(() => {
                                    self.$bvModal.hide("sendTestEmailModal");
                                });

                                self.$swal('Success',
                                    '',
                                    'success'
                                );
                                self.loading = false;

                            })
                            .catch(function (error) {
                                self.loading = false;
                                console.log(error);
                                self.$swal('Error',
                                    'Email sending failed',
                                    'error'
                                );

                            });
                    }
                });
            },

            onSSLChange(event) {
                this.smtpConfigurationDetail.EnableSSL = event.target.value;
            },
        }
    }
</script>


<style lang="scss">
    @import "@core/scss/vue/libs/quill.scss";
</style>